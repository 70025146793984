/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import {ReactComponent as LogoSVG} from "./../../assets/img/convert-logistics-logo.svg";
const DemoNavbar = (props) => {
  const [collapseClasses, setCollapseClasses] = useState('');
  const [collapseState, setCollapseState] = useState(false);
  useEffect(() => {
    if(!(props.autoHide === false)){
      let headroom = new Headroom(document.getElementById("navbar-main"));
      // initialise
      headroom.init();
    }
  }, [])

  const onExiting = () => {
    setCollapseClasses("collapsing-out");
  };

  const onExited = () => {
    setCollapseClasses("");
  };

    return (
      <>
        <header className={"header-global" + (props.type === "solid" ? ' header-solid' : '')}>
          <Navbar
            className={"navbar-main navbar-light navbar-transparent"+(props.autoHide === false ? " headroom headroom--pinned headroom--not-top" : " headroom")}
            expand="lg"
            id="navbar-main"
          >
            <Container className="flex">
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <LogoSVG width={32} height={32} style={{marginRight: 10}} />
                Convert Logistics
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={collapseClasses}
                onExiting={() => onExiting()}
                onExited={()=> onExited()}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/" style={{fontWeight: 'bold'}}>
                        <LogoSVG width={32} height={32} style={{marginRight: 10}} />
                        Convert Logistics
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Products</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          tag={Link}
                          className="d-flex align-items-center"
                          to="/demo/delivery"
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-delivery-fast" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Estimated Shipping and Delivery Dates
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Increase conversion rates by enhancing the shopping experience with estimated shipping and delivery dates.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          tag={Link}
                          className="d-flex align-items-center"
                          to="/demo/location"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-world-2" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Enhanced Location Detection
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Location detection that works! Using advanced machine learning and predictive AI we can determine an accurate zip code for your end users.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          tag={Link}
                          className="d-flex align-items-center"
                          to="/demo/taxes"
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-money-coins" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Estimated Tax
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              When you know a users location, you can enhance the shopping experience with an estimated tax rate in cart.
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Developers</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href={process.env.REACT_APP_ENDPOINT_DOCS}>
                        API Docs
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavLink href="/pricing">Pricing</NavLink>
                  <NavLink href="/contact-us">Contact Us</NavLink>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  {/* <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Like us on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Follow us on Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#"
                      id="tooltip112445449"
                      target="_blank"
                    >
                      <i className="fa fa-github" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Github
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip112445449">
                      Star us on Github
                    </UncontrolledTooltip>
                  </NavItem> */}
                  <NavItem className="d-none d-lg-block">
                    <NavLink href={process.env.REACT_APP_ENDPOINT_DASHBOARD + '/login'} id="btn-signin-header">
                      <Button color="info" type="button" outline>
                        Sign In
                      </Button>
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-none d-lg-block">
                    <NavLink href={process.env.REACT_APP_ENDPOINT_DASHBOARD + '/register'} id="btn-signup-header">
                      <Button color="danger" type="button">
                        Sign Up
                      </Button>
                    </NavLink>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
}

export default DemoNavbar;
