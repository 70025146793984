
import React, { useEffect } from 'react'
import { DesktopAndTabletDevice, isDesktop, isMobile, isTablet, MobileDevice } from 'react-detect';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { ZipCodeLine } from 'ZipCodeLine';
import { CovonyxContext, CovonyxProvider } from 'CovonyxContext';
import { getDemoApiKey } from 'CovonyxContext';


const UpdateZipCodeDisplay = (props) => {
  const { onUpdate = () => { } } = props;
  const { zipCode, setZipCode } = React.useContext(CovonyxContext);
  const input = React.createRef();

  React.useEffect(() => {
    input.current.focus();
  }, [])

  const handleSave = () => {
    setZipCode(input.current.value)
    setTimeout(() => {
      onUpdate();
    }, 500)
  }
  const handleChange = (e) => {
    input.current.value = e.target.value;
  }
  return (<span style={{ display: 'flex' }}>Estimated Tax [
    <input type="text" name="zip_code" pattern="[0-9]*" onKeyUp={(e) => {
      if (e.key === 'Enter') {
        handleSave();
      }
    }} ref={input} style={{ width: 60, borderTop: 0, borderLeft: 0, borderRight: 0, padding: 0 }} />&nbsp;
    <a href="#" onClick={() => handleSave()}><i className="ni ni-check-bold align-bottom" /></a>]:
  </span>
  )
}


const SUBTOTAL = 149.99;


const formatCurrency = (amount, lanugage = 'en-US', currency = 'USD') => {
  return new Intl.NumberFormat(lanugage, { style: 'currency', currency: currency }).format(amount)
}

const EstimatedTaxes = () => {
  const {loading,  zipCode, mapUrl, city, stateCode, additionalTaxLines, setData, getEstimatedTotalAmount, getEstimatedTaxAmount, estimatedTaxRate, estimatedTaxPercent } = React.useContext(CovonyxContext);
  const [shouldUpdate, setShouldUpdate] = React.useState(false);


  useEffect(() => {
    if (window._zt) {
      window._zt.load();
    }
    function taxTotalUpdate(d){
      setData(d)
    }
    window.taxTotalUpdate = taxTotalUpdate;
  }, []);

  return (<Card style={{ minWidth: 300, fontSize: '80%' }}>
    <CardBody>
      {(<table className="table table-borderless">
        <tbody>
          <tr>
            <td className="token bold">Subtotal:</td>
            <td className="text-right">{formatCurrency(SUBTOTAL)}</td>
          </tr>
          <tr>
            <td>
              <MobileDevice>
                <span className="token bold"
                  data-zt-type="estimated-taxes"
                  data-zt-amount={SUBTOTAL * 100}
                  data-zt-currency="US"
                  data-zt-template="Est. Taxes <span style='white-space: nowrap;'>({ESTIMATED_TAX_RATE}) [{ZIP_CODE_UPDATE_LINK}] {HELP_TIP}</span>"
                />
              </MobileDevice>
              <DesktopAndTabletDevice>
                <span className="token bold"
                  data-zt-type="estimated-taxes"
                  data-zt-amount={SUBTOTAL * 100}
                  data-zt-currency="US"
                  data-zt-template="Taxes <span style='white-space: nowrap;'>({ESTIMATED_TAX_RATE}) [{ZIP_CODE_UPDATE_LINK}] {HELP_TIP}</span>"
                  data-zt-callback="taxTotalUpdate"
                />
              </DesktopAndTabletDevice>
            </td>
            <td className="text-right"
              data-zt-type="estimated-taxes"
              data-zt-amount={SUBTOTAL * 100}
              data-zt-currency="USD"
              data-zt-template="{ESTIMATED_TAX_AMOUNT}">
            </td>
          </tr>
          {/* {estimatedTaxRate ? (<tr>
            <td className="token bold">

            <ZipCodeLine byLine={"Estimated Tax (" + estimatedTaxPercent + "%) [zipCode]learnMore"} /></td>
            <td className="text-right">${getEstimatedTaxAmount(SUBTOTAL).toFixed(2)}</td>
          </tr>) : null} */}
          {additionalTaxLines.length > 0 ? (<>
            {additionalTaxLines.map((line, i) => {
              return <tr key={i}>
                <td className="token bold">
                  {line.title}:
                </td>
                <td className="text-right">${formatCurrency(line.amount)}</td>
              </tr>
            })}
          </>) : null}
        </tbody>
        <tfoot style={{ borderTop: '1px solid #ccc' }}>
          <tr>
            <td className="token bold">Total:</td>
            <td className="text-right">{formatCurrency((getEstimatedTotalAmount(SUBTOTAL)).toFixed(2))}</td>
          </tr>
        </tfoot>
      </table>)}
    </CardBody>
  </Card>
  )
}

export default function EstimatedTaxesDisplay() {
  return (
    <CovonyxProvider apiKey={getDemoApiKey()}>
      <EstimatedTaxes />
    </CovonyxProvider>
  )
}