import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import CardsFooter from "components/Footers/CardsFooter";
import DemoNavbar from "components/Navbars/DemoNavbar"
import Pricing from "components/Pricing";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

export const PricingPage = () => {
    return (<>
        <DemoNavbar />
        <main>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <BackgroundFloatingBoxes color="default" />
                    <Container className="py-lg-md mt-5">
                        <h1 className="text-white">Our Pricing</h1>
                        <h3 className="text-white">All plans include a 14-day free trial!</h3>
                        <h5 className="text-white">We offer affordable plans for businesses of all sizes</h5>
                        <Pricing />
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
            </div>

        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}