

export const BackgroundFloatingBoxes =(props) => {
  const { color = "default" } = props;
    return (
        <div className={"shape shape-style-1 shape-"+color}>

          <ul className="bg-bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        );
}