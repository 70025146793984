import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import CardsFooter from "components/Footers/CardsFooter";
import DemoNavbar from "components/Navbars/DemoNavbar"
// reactstrap components
import {
    Container,
    Row,
    Col,
    CardImg
} from "reactstrap";
import EstimatedTaxesDisplay from "./EstimatedTaxesDisplay";

export const EstimatedTaxesDemo = () => {
    return (<>
        <DemoNavbar />
        <main>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <BackgroundFloatingBoxes color="primary" />
                    <Container className="py-lg-md d-flex mt-5">
                        <div className="col px-0">
                            <Row>
                                <Col lg="5">
                                    <h1 className="display-3 text-white">
                                        Estimated Taxes
                                    </h1>
                                </Col>
                                <Col lg="7">
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <EstimatedTaxesDisplay />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
            </div>
            <section className="section">
                <Container>
                    <Row className="row-grid">
                        <Col className="order-md-2" md="6">
                            <CardImg
                                alt="..."
                                src={'/img/tax-animate.svg'}
                                top
                            />
                        </Col>
                        <Col className="order-md-1" md="6">
                            <div className="pr-md-5">
                                <div className="pl-md-5">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="icon icon-md icon-shape icon-shape-primary shadow rounded-circle">
                                            <i className="ni ni-money-coins" />
                                        </div> <h3 className="pl-md-3">Calculate Taxes in Cart</h3>
                                    </div>
                                    <p>
                                        Our estimated tax solutions are perfect for calculating state taxes in the cart, long before the user get to checkout.
                                        Users that continue to checkout are comitted to purchase, but many of these users get cold feet
                                        when the price increases during the checkout phase.
                                    </p>
                                    <p>
                                        Increase your conversion rates and prevent users from bouncing during checkout
                                        by estimating taxes up-front during the user journey by surfacing the tax payment in the cart.
                                        With our reliable zip code detection, you can more closely estimate the taxes the user will incur
                                        during checkout.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}