import DemoNavbar from "components/Navbars/DemoNavbar"
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// Put your API key here
builder.init('9f3f7fec6a9d4186b0c1487e617e141c')

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export function Content() {
    const isPreviewingInBuilder = useIsPreviewing();
    const [notFound, setNotFound] = useState(false);
    const [content, setContent] = useState(null);
    const location = useLocation();

    // get the page content from Builder
    useEffect(() => {
        const fetchContent = async () => {
            const content = await builder.get('page', {
                url: location.pathname
            }).promise();

            setContent(content);
            setNotFound(!content);
        }
        fetchContent();
    }, [location.pathname]);
    // if no page is found, return a 404 page
    if (notFound && !isPreviewingInBuilder) {
        return <>
            <h1>404</h1>
        </>
    }

    // return the page when found
    return (
        <>
            <Helmet>
                <title>{content?.data.title}</title>
            </Helmet>
            <DemoNavbar type="solid" />
            <main>
                {/* Render the Builder page */}
                <BuilderComponent model="page" content={content} />
            </main>
        </>
    );
}