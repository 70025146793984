import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import DemoNavbar from "components/Navbars/DemoNavbar"
import LocationDetectDisplay from "./LocationDetectDisplay"
// reactstrap components
import {
    Container,
    Row,
    Col,
    CardImg
} from "reactstrap";
import CardsFooter from "components/Footers/CardsFooter";

export const LocationDetectionDemo = () => {
    return (<>
        <DemoNavbar />
        <main>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <BackgroundFloatingBoxes color="default" />
                    <Container className="py-lg-md d-flex mt-5">
                        <div className="col px-0">
                            <Row>
                                <Col xs="12" md="6">
                                    <h1 className="display-3 text-white">
                                        Location Detection
                                    </h1>
                                    <p className="text-white">Our Location Detection SDK can guess the users preferred zip code for order delivery, store locators, webpage translation, etc. 
                                    <br />
                                    <br />
                                    The display can be custom formatted by your development team to meet your websites design.</p>
                                </Col>
                                <Col sx="12" md="6">
                                    <LocationDetectDisplay />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
            </div>
            <section className="section section-sm">
                <Container>
                    <Row className="row-grid">
                        <Col className="order-md-2" md="6">
                            <CardImg
                                alt="..."
                                src={'/img/globalization-animate.svg'}
                                top
                            />
                        </Col>
                        <Col className="order-md-1" md="6">
                            <div className="pr-md-5">

                                <div className="d-flex align-items-center mb-3">
                                    <div className="icon icon-md icon-shape icon-shape-primary shadow rounded-circle">
                                        <i className="ni ni-world-2" />
                                    </div>
                                    <h3 className="pt-0 pl-md-3">Location Detection</h3>
                                </div>
                                <p>
                                    Geolocation by IP can only do so much and browser based location detection requires hightened security permissions which is annoying and invasive to the user.
                                    Internet Service Providers (ISPs) do not operate in just one zip code, therefore, pinpointing accuracy becomes very difficult since the IP is usually resolved to the local ISP network, not the user/device using it.
                                    In our analysis, we found IP to Zip geolocation to be around 12% accurate. Our solution bridges the gap left by geo-to-IP services and brings our accuracy to around 98%!
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}