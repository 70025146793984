import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import DemoNavbar from "components/Navbars/DemoNavbar"
import EstimatedDeliveryDatesDisplay, { EstimatedDeliveryDateProductCard, modifySkus } from "./EstimatedDeliveryDatesDisplay"
// reactstrap components
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import React, { useState } from "react";
import CardsFooter from "components/Footers/CardsFooter";
import { getDomain } from "CovonyxContext";
import { useLocation } from "react-router-dom";

export const EstimatedDeliveryDateProductCardDemo = () => {
    const location = useLocation();
    const [zipFrom, setZipFrom] = useState(90210);
    const [zipTo, setZipTo] = useState(null);
    const [themePrimary, setThemePrimary] = useState('#5e72e4');
    const [themeSecondary, setThemeSecondary] = useState('#ffffff');
    const [dateFormat, setDateFormat] = useState('D, M jS');
    // const [zipTo, setZipTo] = useState(70815);
    const [loading, setLoading] = useState(false);
    const [arrivesByLabel, setArrivesByLabel] = useState('Delivery by');
    const [template, setTemplate] = useState(`<div>{TRANSLATE_SHIPPING_TO} {ZIP_CODE_UPDATE_LINK} {HELP_TIP}</div>
<div>{ESTIMATED_DELIVERY_BYLINE} {ESTIMATED_DELIVERY_DATE}</div>
<div>{ORDERED_IN_TIME_TEXT} {ORDERED_IN_TIME_COUNTDOWN}</div>`);
    const [displayType, setDisplayType] = useState('default');

    React.useEffect(() => {
        if(window._zt){
          window._zt.load()
        }
      }, [location]);

    const handleChange = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            window._zt.reload();
        }, 100)
    }

    const handleZipFromChange = (from) => {
        setZipFrom(from.target.value);
        handleChange();
    }
    // const handleZipToChange = (to) => {
    //     setZipTo(to.target.value);
    //     handleChange();
    // }

    return (<>
        <Container>
            <h3 className="pl-md-3">Delivery Playground</h3>
            <hr />
            <Row className="row-grid flip-direction">
                <Col className="order-md-1" md="4" sm="3">
                    {loading ? null : (
                        <EstimatedDeliveryDateProductCard
                            sku={modifySkus[0].sku}
                            imageUrl={modifySkus[0].image}
                            name={modifySkus[0].name}
                            price={modifySkus[0].price}
                            zipCodeFrom={zipFrom}
                            zipCodeTo={zipTo}
                            cacheResponse={true}
                            byline={arrivesByLabel}
                            template={template}
                            displayType={displayType}
                            dateFormat={dateFormat}
                            themePrimary={themePrimary}
                            themeSecondary={themeSecondary}
                        />
                    )}
                </Col>
                <Col className="order-md-2" md="8" sm="9">
                    <div className="grid">
                        <FormGroup>
                            <Label>Warehouse Zip Code:</Label>
                            <Input name="zipFrom" type="text" defaultValue={zipFrom} onBlur={handleZipFromChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Zip Code To (Random list):</Label>
                            <Input type="select" className="form-control" name="zipTo" onChange={(e) => {
                                setZipTo(e.target.value);
                                handleChange();
                            }}>
                                <option value="30303">[30303] - Atlanta, GA</option>
                                <option value="06611">[06611] - Trumbull, CT</option>
                                <option value="52501">[52501] - Ottumwa, IA</option>
                                <option value="22079">[22079] - Lorton, VA</option>
                                <option value="54481">[54481] - Stevens Point, WI</option>
                                <option value="60053">[60053] - Morton Grove, IL</option>
                                <option value="49428">[49428] - Jenison, MI</option>
                                <option value="20706">[20706] - Lanham, MD</option>
                                <option value="14450">[14450] - Fairport, NY</option>
                                <option value="53051">[53051] - Menomonee Falls, WI</option>
                                <option value="08096">[08096] - West Deptford, NJ</option>
                                <option value="34116">[34116] - Naples, FL</option>
                                <option value="44095">[44095] - Eastlake, OH</option>
                                <option value="78023">[78023] - Helotes, TX</option>
                                <option value="38655">[38655] - Oxford, MS</option>
                                <option value="35758">[35758] - Madison, AL</option>
                                <option value="29576">[29576] - Murrells Inlet, SC</option>
                                <option value="24112">[24112] - Martinsville, VA</option>
                                <option value="22101">[22101] - Mc Lean, VA</option>
                                <option value="67037">[67037] - Derby, KS</option>
                                <option value="38654">[38654] - Olive Branch, MS</option>
                                <option value="30269">[30269] - Peachtree City, GA</option>
                                <option value="53066">[53066] - Oconomowoc, WI</option>
                                <option value="15301">[15301] - Washington, PA</option>
                                <option value="32707">[32707] - Casselberry, FL</option>
                                <option value="06484">[06484] - Shelton, CT</option>
                                <option value="27502">[27502] - Apex, NC</option>
                                <option value="44720">[44720] - North Canton, OH</option>
                                <option value="07450">[07450] - Ridgewood, NJ</option>
                                <option value="70663">[70663] - Sulphur, LA</option>
                                <option value="18062">[18062] - Macungie, PA</option>
                                <option value="28348">[28348] - Hope Mills, NC</option>
                                <option value="35124">[35124] - Pelham, AL</option>
                                <option value="21093">[21093] - Lutherville Timonium, MD</option>
                                <option value="59601">[59601] - Helena, MT</option>
                                <option value="19002">[19002] - Ambler, PA</option>
                                <option value="18974">[18974] - Warminster, PA</option>
                                <option value="43701">[43701] - Zanesville, OH</option>
                                <option value="21042">[21042] - Ellicott City, MD</option>
                                <option value="55104">[55104] - Saint Paul, MN</option>
                                <option value="11554">[11554] - East Meadow, NY</option>
                                <option value="06902">[06902] - Stamford, CT</option>
                                <option value="07202">[07202] - Elizabeth, NJ</option>
                                <option value="07302">[07302] - Jersey City, NJ</option>
                                <option value="29461">[29461] - Moncks Corner, SC</option>
                                <option value="60004">[60004] - Arlington Heights, IL</option>
                                <option value="37076">[37076] - Hermitage, TN</option>
                                <option value="11550">[11550] - Hempstead, NY</option>
                                <option value="48446">[48446] - Lapeer, MI</option>
                                <option value="60035">[60035] - Highland Park, IL</option>
                                <option value="46077">[46077] - Zionsville, IN</option>
                                <option value="33470">[33470] - Loxahatchee, FL</option>
                                <option value="11691">[11691] - Far Rockaway, NY</option>
                                <option value="19426">[19426] - Collegeville, PA</option>
                                <option value="16801">[16801] - State College, PA</option>
                                <option value="60439">[60439] - Lemont, IL</option>
                                <option value="30809">[30809] - Evans, GA</option>
                                <option value="40356">[40356] - Nicholasville, KY</option>
                                <option value="20854">[20854] - Potomac, MD</option>
                                <option value="11561">[11561] - Long Beach, NY</option>
                                <option value="27834">[27834] - Greenville, NC</option>
                                <option value="85326">[85326] - Buckeye, AZ</option>
                                <option value="06340">[06340] - Groton, CT</option>
                                <option value="20901">[20901] - Silver Spring, MD</option>
                                <option value="44039">[44039] - North Ridgeville, OH</option>
                                <option value="02860">[02860] - Pawtucket, RI</option>
                                <option value="38671">[38671] - Southaven, MS</option>
                                <option value="11735">[11735] - Farmingdale, NY</option>
                                <option value="22801">[22801] - Harrisonburg, VA</option>
                                <option value="01867">[01867] - Reading, MA</option>
                                <option value="49855">[49855] - Marquette, MI</option>
                                <option value="03053">[03053] - Londonderry, NH</option>
                                <option value="90505">[90505] - Torrance, CA</option>
                                <option value="53151">[53151] - New Berlin, WI</option>
                                <option value="07666">[07666] - Teaneck, NJ</option>
                                <option value="18944">[18944] - Perkasie, PA</option>
                                <option value="18201">[18201] - Hazleton, PA</option>
                                <option value="60048">[60048] - Libertyville, IL</option>
                                <option value="06473">[06473] - North Haven, CT</option>
                                <option value="75088">[75088] - Rowlett, TX</option>
                                <option value="21222">[21222] - Dundalk, MD</option>
                                <option value="36301">[36301] - Dothan, AL</option>
                                <option value="28173">[28173] - Waxhaw, NC</option>
                                <option value="60621">[60621] - Chicago, IL</option>
                                <option value="48101">[48101] - Allen Park, MI</option>
                                <option value="20886">[20886] - Montgomery Village, MD</option>
                                <option value="53045">[53045] - Brookfield, WI</option>
                                <option value="33056">[33056] - Miami Gardens, FL</option>
                                <option value="29301">[29301] - Spartanburg, SC</option>
                                <option value="39564">[39564] - Ocean Springs, MS</option>
                                <option value="84119">[84119] - Salt Lake City, UT</option>
                                <option value="33460">[33460] - Lake Worth, FL</option>
                                <option value="48237">[48237] - Oak Park, MI</option>
                                <option value="02919">[02919] - Johnston, RI</option>
                                <option value="32566">[32566] - Navarre, FL</option>
                                <option value="06074">[06074] - South Windsor, CT</option>
                                <option value="19406">[19406] - King Of Prussia, PA</option>
                                <option value="43026">[43026] - Hilliard, OH</option>
                                <option value="60156">[60156] - Lake In The Hills, IL</option>
                                <option value="53590">[53590] - Sun Prairie, WI</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Arrives By:</Label>
                            <Input name="arrives" type="text" defaultValue={arrivesByLabel} onBlur={(e) => {
                                setArrivesByLabel(e.target.value);
                                handleChange();
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Display Type: </Label>
                            <Input type="select" className="form-control" name="displayType" defaultValue={displayType} onChange={(e) => {
                                setDisplayType(e.target.value);
                                handleChange();
                            }}>
                                <option value="default">Default</option>
                                <option value="range">Range</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Date Format: [<a href={`${process.env.REACT_APP_ENDPOINT_DOCS}/docs/js-sdk/estimated-delivery-dates/date-format`} target="_blank">Date Docs</a>]</Label>
                            <Input name="dateFormat" type="text" defaultValue={dateFormat} onBlur={(e) => {
                                setDateFormat(e.target.value);
                                handleChange();
                            }} />
                        </FormGroup>
                        <div style={{display: 'grid', gap: 3, gridTemplateColumns: '1fr 1fr'}}>
                            <FormGroup>
                                <Label>Primary:</Label>
                                <Input name="themePrimary" type="color" defaultValue={themePrimary} onBlur={(e)=>{
                                    setThemePrimary(e.target.value)
                                        handleChange();
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Secondary:</Label>
                                <Input name="themeSecondary" type="color" defaultValue={themeSecondary} onBlur={(e)=>{
                                    setThemeSecondary(e.target.value)
                                        handleChange();
                                }} />
                            </FormGroup>
                        </div>
                        
                    </div>
                    <FormGroup>
                        <Label>Template: [<a href={`${process.env.REACT_APP_ENDPOINT_DOCS}/docs/js-sdk/estimated-delivery-dates/installation#templating`} target="_blank">Template Docs</a>]</Label>
                        <Input name="template" type="textarea" style={{ height: 250 }} defaultValue={template.replaceAll('<br />', '\n<br />\n')} onBlur={(e) => {
                            setTemplate(e.target.value);
                            handleChange();
                        }} />
                    </FormGroup>
                    {/* <Label>To Zip Code:</Label>
                    <Input name="zipTo" type="text" defaultValue={zipTo} onBlur={handleZipToChange} /> */}
                </Col>
            </Row>
        </Container>
    </>
    )
}