

import { useEffect, useState } from "react"
import { Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, CloseButton } from "reactstrap"
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    companyName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    comments: Yup.string()
        .min(2, 'Too Short!')
        .max(255, 'Too Long!'),
});

const CustomInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    return (<div>
        <Input
            {...field}
            {...props}
            className={field.className + (touched[field.name] && errors[field.name] !== undefined ? ' is-invalid' : '')} />
        {touched[field.name] && errors[field.name] !== undefined ? <div className="invalid-feedback">{errors[field.name]}</div> : null}
    </div>)
};

export default function ContactUsForm({onSubmitSuccess = () => {}, onCancel = () => {}, hideCancelButton = false}) {
    const [loading, setLoading] = useState(false);

    return (
                <Formik
                    initialValues={{
                        name: '',
                        companyName: '',
                        email: '',
                        comments: '',
                        neverfill: ''
                    }}
                    validationSchema={ContactSchema}
                    onSubmit={async (values) => {
                        setLoading(true)
                        if ((typeof values.neverfill === 'string' && values.neverfill.length === 0)) {
                            fetch(process.env.REACT_APP_ENDPOINT_WEB + '/contact/submit', {
                                method: "POST", // *GET, POST, PUT, DELETE, etc.
                                mode: "no-cors", // no-cors, *cors, same-origin
                                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                                headers: {
                                    "Content-Type": "application/json",
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                body: JSON.stringify(values)
                            }).then(d => {
                                onSubmitSuccess(d);
                            }).finally(() => {
                                setLoading(false);
                            })
                        }else{
                            setLoading(false);
                        }
                    }}
                >
                    <Form>
                        <p>Interested in learning more?</p>
                        <div>
                            <Field disabled={loading} type="text" name="name" placeholder="Name" component={CustomInputComponent} />
                            <br />
                            <Field disabled={loading} type="email" name="email" placeholder="Email" component={CustomInputComponent} />
                            <br />
                            <Field disabled={loading} type="text" name="companyName" placeholder="Company Name" component={CustomInputComponent} />
                            <br />
                            <Field disabled={loading} type="textarea" name="comments" placeholder="Comments. How did you hear about us? What interests you about the product?" component={CustomInputComponent} style={{height: 150}} />
                            <Field disabled={loading} type="text" name="neverfill" autoComplete="off" autofill="off" placeholder="" tabIndex={-1} style={{ border: 0, background: 'transparent' }} />
                        </div>
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'right' }}>
                            {hideCancelButton ? <></> : <Button onClick={() => onCancel()}>Close</Button>}
                            <Button disabled={loading} color="primary" type="submit">Submit</Button>
                        </div>
                    </Form>
                </Formik>
    )

}