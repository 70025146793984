import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import DemoNavbar from "components/Navbars/DemoNavbar"
import EstimatedDeliveryDatesDisplay from "./EstimatedDeliveryDatesDisplay"
// reactstrap components
import {
    Container,
    Row,
    Col,
    CardImg
} from "reactstrap";
import React from "react";
import CardsFooter from "components/Footers/CardsFooter";
import { EstimatedDeliveryDateProductCardDemo } from "./EstimatedDeliveryDateProductCardDemo";
import { getDemoApiKey } from "CovonyxContext";
import { CovonyxProvider } from "CovonyxContext";

export const EstimatedDeliveryDatePlayground = () => {
    return (<>
        <DemoNavbar autoHide={false} />
        <main>
            <section className="section section-lg section-shaped pb-250 bg-secondary">
                {/* <BackgroundFloatingBoxes /> */}
                <Container className="py-lg-md d-flex p-lg-4">
                    <CovonyxProvider apiKey={getDemoApiKey()}>
                        <EstimatedDeliveryDateProductCardDemo />
                    </CovonyxProvider>
                </Container>
            </section>
        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}