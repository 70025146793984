import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import CardsFooter from "components/Footers/CardsFooter";
import DemoNavbar from "components/Navbars/DemoNavbar"
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

export const Attributions = () => {
    return (<>
        <DemoNavbar />
        <main>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <BackgroundFloatingBoxes color="default" />
                    <Container className="py-lg-md d-flex mt-5">
                        <div className="col px-0">
                            <Row>
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        Attributions
                                    </h1>
                                    <a className="text-white" href="https://storyset.com/people">People illustrations by Storyset</a>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
            </div>

        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}