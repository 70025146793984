/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index.js";
import { LocationDetectionDemo } from "views/demos/LocationDetectionDemo";
import { EstimatedTaxesDemo } from "views/demos/EstimatedTaxesDemo";
import { EstimatedDeliveryDateDemo } from "views/demos/EstimatedDeliveryDateDemo";
import { Attributions } from "views/Attributions";
import ReactGA from 'react-ga4';
import { Content } from "views/Content";
import { DetectProvider } from 'react-detect';
import { EstimatedDeliveryDatePlayground } from "views/demos/EstimatedDeliveryDatePlayground";
import { PricingPage } from "views/PricingPage";
import { ContactUsPage } from "views/ContactUsPage";


if(process.env.NODE_ENV === 'production'){
  const GA_TRACKING_ID = "G-LPQ37PFBFZ"; // OUR GOOGLE ANALYTICS TRACKING ID
  ReactGA.initialize(GA_TRACKING_ID);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const Router = () => {

  useEffect(() => {
    if(process.env.NODE_ENV === 'production'){
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    }
  }, []);

  return (<BrowserRouter>
  <DetectProvider>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route path="/login" exact render={(props) => {window.location.href = "/dashboard/login";}} />
      <Route path="/register" exact render={(props) => {window.location.href = "/dashboard/register";}} />
      <Route
        path="/attributions"
        exact
        render={(props) => <Attributions {...props} />}
      />
      <Route
        path="/pricing"
        exact
        render={(props) => <PricingPage {...props} />}
      />
      <Route
        path="/contact-us"
        exact
        render={(props) => <ContactUsPage {...props} />}
      />
      <Route
        path="/demo/location"
        exact
        render={(props) => <LocationDetectionDemo {...props} />}
      />
      <Route
        path="/demo/taxes"
        exact
        render={(props) => <EstimatedTaxesDemo {...props} />}
      />
      <Route
        path="/demo/delivery"
        exact
        render={(props) => <EstimatedDeliveryDateDemo {...props} />}
      />
      <Route
        path="/playground"
        exact
        render={(props) => <EstimatedDeliveryDatePlayground {...props} />}
      />
      <Route
        path="/*"
        exact
        render={(props) => <Content {...props} />}
      />
      {/* <Redirect to="/" /> */}
    </Switch>
    </DetectProvider>
  </BrowserRouter>)
};
root.render(<Router />)