import ContactUsForm from "components/ContactUsForm";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
export default function ContactUsRequestPopup({delaySeconds = 0}) {
    const [open, setOpen] = useState(false);
    const previouslySubmitted = localStorage.getItem('contact-form') === 'closed';

    useEffect(() => {
        if(!previouslySubmitted){
            setTimeout(()=>{
                setOpen(true);
            }, delaySeconds * 1000);
        }
    },[delaySeconds]);

    const handleClose = () => {

        localStorage.setItem('contact-form', 'closed');
        setOpen(false);

    }

    return (open && 
        <Modal isOpen={open}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #eee',
                padding: '1rem 1.5rem'
            }}>
                Contact Us
                <Button size='sm' onClick={() => handleClose()}>&times;</Button>
            </div>
            <ModalBody>
                <ContactUsForm onSubmitSuccess={() => {
                    handleClose()
                }} onCancel={() => handleClose()} />
            </ModalBody>
        </Modal>
    )

}