import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import ContactUsForm from "components/ContactUsForm";
import CardsFooter from "components/Footers/CardsFooter";
import DemoNavbar from "components/Navbars/DemoNavbar"
import { useState } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
    Alert
} from "reactstrap";

export const ContactUsPage = () => {
    const [successMessage, setSuccessMessage] = useState(null);
    return (<>
        <DemoNavbar />
        <main>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <BackgroundFloatingBoxes color="default" />
                    <Row className="p-2">
                        <Col xs={1} md={3} lg={4}></Col>
                        <Col xs={10} md={6} lg={4} className="p-4 mt-5 bg-secondary">
                        {successMessage !== null ? (
                            <Alert color="success">{successMessage}</Alert>
                        ):(
                            <ContactUsForm 
                                onSubmitSuccess={()=>{
                                    setSuccessMessage("Thanks for reaching out! We'll get back as soon as we can.")
                                }} 
                                hideCancelButton={true} 
                            />
                        )}
                        </Col>
                    </Row>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
            </div>

        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}