
import React, { useEffect } from 'react'
import { Button, Card, CardBody, Input } from 'reactstrap';
import { ZipCodeLine } from 'ZipCodeLine';
import { CovonyxContext, CovonyxProvider } from 'CovonyxContext';
import { getDemoApiKey } from 'CovonyxContext';


const ZipGuess = () => {
  const [showAccuracyFeedback, setShowAccuracyFeedback] = React.useState(true);
  const ztLocRef = React.useRef();
  useEffect(() => {
    if (window._zt) {
      window._zt.load();
    }
  }, []);
  return (<Card className="zip-guess">
    <CardBody>
      <div style={{ height: 50 }}>
        <span id="zt-location" data-zt-type="location" ref={ztLocRef}></span>
      </div>
      {showAccuracyFeedback ? (
        <p className="pt-2 mb-0">Is this correct?{"  "}
          <button className="btn btn-secondary btn-neutral btn-sm" onClick={() => setShowAccuracyFeedback(false)}>
            <i className="ni ni-like-2 align-middle"></i> Yes
          </button>
          <button className="btn btn-secondary btn-neutral btn-sm" onClick={() => {
            window._zt._IC.updateTemplate(ztLocRef.current, 'update-location')
            setShowAccuracyFeedback(false);
          }}>
            <i className="ni ni-like-2 rotate-180 align-middle"></i> No
          </button>
        </p>) : (
        <p className="pt-2">Thanks for the feedback!</p>
      )}
    </CardBody>
  </Card>
  )
}

export default function LocationDetectDisplay() {
  return (
    <CovonyxProvider apiKey={getDemoApiKey()}>
      <ZipGuess />
    </CovonyxProvider>
  )
}