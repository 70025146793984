/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Pricing from "components/Pricing.js";
import LocationDetectDisplay from "./demos/LocationDetectDisplay";
import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes";
import { EstimatedDeliveryDate, EstimatedDeliveryDateProductCardDisplay, sampleSkus } from "./demos/EstimatedDeliveryDatesDisplay";
import { Stack } from "react-bootstrap";
import DeveloperDocsCallout from "components/DeveloperDocsCallout";
import { CovonyxProvider } from "CovonyxContext";
import { getDemoApiKey } from "CovonyxContext";
import EstimatedTaxesDisplay from "./demos/EstimatedTaxesDisplay";
import { DesktopAndTabletDevice } from "react-detect";
/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Landing = () => {
  const main = React.useRef();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    main.scrollTop = 0;
  }, [])

  const messages = [
    // {
    //   title: `"When will my product arrive?"`,
    //   body1: `The question on every customers mind when deciding to purchase a product on your website. <br /> You've thought about it while you shop online and so do your customers.<br /><br />Let your customers know when they can expect delivery of their items <em>before they purchase</em>`,
    //   body2: `Our Javascript SDK can be installed in less than 10 minutes!`
    // },
    {
      title: `Estimate delivery before cart`,
      body1: `Increase your Add to Cart rate by letting your customers know when they can expect delivery of their items <em>before they purchase</em>.`,
      body2: `Increase your Add To Cart rate today with a 14-day free trial`
    }
  ];

  const messageId = getRandomInt(0, messages.length - 1);
  const message = messages[messageId];

  const [skuToShow, setSkuToShow] = useState(getRandomInt(0, 5));


  return (
    <>
      <DemoNavbar />
      <main ref={main}>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <BackgroundFloatingBoxes />
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row className="flex justify-left align-center">
                  <Col lg="6" md="7" sm="12">

                    {message.title && <h1 className="display-3 text-white" dangerouslySetInnerHTML={{ __html: message.title }} />}
                    {message.body1 && <p className="lead text-white" dangerouslySetInnerHTML={{ __html: message.body1 }} />}
                    {message.body2 && <p className="lead text-white" dangerouslySetInnerHTML={{ __html: message.body2 }} />}

                    {/* <p className="lead text-white">
                      Our predictive logistics will compute a date for delivery based on <em>your</em> fulfillment data as well as our network of delivery transit data. We don't just look at transit tables, we analyze transit history to predict delivery. 
                      </p> */}
                    <div className="flex justify-left">
                      {/* <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          target="_blank"
                          href={process.env.REACT_APP_ENDPOINT_DOCS + '/docs/intro'}
                        >
                          <span className="btn-inner--text">
                            Get Started
                          </span>
                        </Button> */}

                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0 ml-0"
                        color="default"
                        href="/demo/delivery"
                      >
                        <span className="btn-inner--text">
                          Learn More
                        </span>
                      </Button>
                      <Button
                        className="btn-icon mb-3 mb-sm-0 ml-1"
                        color="warning"
                        href="/pricing"
                      >
                        <span className="btn-inner--text">
                          14-day Free Trial
                        </span>
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6" md="5" sm="12">
                    <div className="card-demo-homepage">
                      <span className="large-arrow-container">
                        <span className="large-arrow-text">We use AI to predict delivery!</span>
                        <span className="large-arrow">&#x2937;</span>
                      </span>
                      {/* <LocationDetectDisplay /> */}
                      <EstimatedDeliveryDateProductCardDisplay skuId={skuToShow} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>

        <a id="estimated-delivery" name="estimated-delivery"></a>
        <section className="section">

          <Container>
            <Row className="row-grid">
              <Col className="order-2 order-lg-1 mt-6 mt-lg-0 bg-secondary flex justify-center align-center p-3" lg="4">
                <Card>
                  <CardBody>
                    <CovonyxProvider apiKey={getDemoApiKey()}>
                      <EstimatedDeliveryDate
                        sku={sampleSkus[skuToShow].sku}
                        imageUrl={sampleSkus[skuToShow].imageUrl}
                        name={sampleSkus[skuToShow].name}
                        price={sampleSkus[skuToShow].price}
                        zipCodeFrom={sampleSkus[skuToShow].zipFrom}
                      />
                    </CovonyxProvider>
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-lg-2" lg="8">
                <div className="pl-lg-5">
                  <div className="d-flex align-items-center mb-3">
                    <DesktopAndTabletDevice>
                    <div className="icon icon-md icon-shape icon-shape-primary shadow rounded-circle">
                      <i className="ni ni-delivery-fast" />
                    </div>
                    </DesktopAndTabletDevice>
                    <h3 className="pl-md-2">Estimated Shipping &amp; Delivery Dates</h3>
                  </div>
                  <p>
                    With the power of our location detection, we can predict how long it will take to deliver an item to a customer <i>before</i> they get to checkout and purchase the item.
                  </p>
                  <p>
                    Our system will take into account your company business days, holidays, warehouse locations, product inventory, and fulfillment lead times along with any upcoming carrier holidays, delays, routing, and any major weather events. Combined with an accurate prediction of the customers location, our system will return an estimated delivery date unique to the item and customer.
                  </p>
                  <Row>
                    <Col xs={12} md={4}>
                  <a
                    className="font-weight-bold text-warning mt-5"
                    href="/demo/delivery"
                  >
                    Learn More
                  </a>
                  </Col>
                    <Col xs={12} md={4}>
                  <a
                    className="font-weight-bold text-warning mt-5"
                    href="/playground"
                  >
                    Customize Demo
                  </a>
                  </Col>
                    <Col xs={12} md={4}>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href={process.env.REACT_APP_ENDPOINT_DOCS + '/docs/category/estimated-delivery-dates'}
                      target="_blank"
                    >
                      View Documentation
                    </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-secondary">
          <Container>
            <h3>Admin Panel</h3>
            <p>Manage all aspects of shipping estimates. Configure your shipping carriers, holidays, fulfillment time, etc. See where your customers are coming from and what each orders estimated delivery time was so you can prioritize fulfillment so you can meet customer's expectations. </p>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 14 }}>
              {Array(6).fill().map((d, i) => {
                const url = '/img/admin-' + (i + 1) + '.png';
                return <a href={url} target="_blank" key={i}><img src={url} alt="Admin panel example" style={{ width: '100%', borderRadius: 5 }} /></a>
              })}
            </div>
          </Container>
        </section>
        <a id="location" name="location"></a>
        <section className="section">
          <Container>
            <Row className="row-grid">
              <Col className="order-md-1" lg="8">
                <div className="pl-lg-5">
                  <div className="d-flex align-items-center mb-3">
                    <DesktopAndTabletDevice>
                    <div className="icon icon-md icon-shape icon-shape-primary shadow rounded-circle">
                      <i className="ni ni-world-2" />
                    </div>
                    </DesktopAndTabletDevice>
                    <h3 className="pt-0 pl-md-2">Location Detection</h3>
                  </div>
                  <p>
                    Geolocation by IP can only do so much and browser based location detection requires hightened security permissions which is annoying and invasive to the user.
                    Internet Service Providers (ISPs) do not operate in just one zip code, therefore, pinpointing accuracy becomes very difficult since the IP is usually resolved to the local ISP network, not the user/device using it.
                    In our analysis, we found IP to Zip geolocation to be around 12% accurate. Our solution bridges the gap left by geo-to-IP services and brings our accuracy to around 98%!
                  </p>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                  <a
                    className="font-weight-bold text-warning mt-5"
                    href="/demo/location"
                  >
                    View the Demo
                  </a>
                  </Col>
                    <Col xs={12} md={6} lg={4}>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href={process.env.REACT_APP_ENDPOINT_DOCS + '/docs/category/location-detection'}
                      target="_blank"
                    >
                      View Documentation
                    </a>
                  </Col>
                  </Row>
                </div>
              </Col>

              <Col className="order-md-1 bg-secondary flex justify-center align-center p-sm-3" lg="4">
                <LocationDetectDisplay />
                {/* <CardImg
                    alt="..."
                    src={'/img/globalization-animate.svg'}
                    top
                  /> */}
              </Col>
            </Row>
          </Container>
        </section>
        <a id="estimated-taxes" name="estimated-taxes"></a>
        <section className="section bg-secondary">
          <Container>
            <Row className="row-grid">
              <Col className="order-2 order-lg-1 mt-6 mt-lg-0" lg="4">
                {/* <CardImg
                    alt="..."
                    src={'/img/tax-animate.svg'}
                    top
                  /> */}
                <EstimatedTaxesDisplay />
              </Col>
              <Col className="order-1 order-lg-2" lg="8">
                <div className="pl-lg-5">
                  <div className="d-flex align-items-center mb-3">
                    <DesktopAndTabletDevice>
                    <div className="icon icon-md icon-shape icon-shape-primary shadow rounded-circle">
                      <i className="ni ni-money-coins" />
                    </div>
                    </DesktopAndTabletDevice> 
                    <h3 className="pl-md-2">Calculate Taxes in Cart</h3>
                  </div>
                  <p>
                    Our estimated tax solutions are perfect for calculating state taxes in the cart, long before the user get to checkout.
                    Users that continue to checkout are comitted to purchase, but many of these users get cold feet
                    when the price increases during the checkout phase.
                  </p>
                  <p>
                    Increase your conversion rates and prevent users from bouncing during checkout
                    by estimating taxes up-front during the user journey by surfacing the tax payment in the cart.
                    With our reliable zip code detection, you can more closely estimate the taxes the user will incur
                    during checkout.
                  </p>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="/demo/taxes"
                    >
                      View the Demo
                    </a>
                  </Col>
                    <Col xs={12} md={6} lg={4}>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href={process.env.REACT_APP_ENDPOINT_DOCS + '/docs/category/estimated-taxes'}
                      target="_blank"
                    >
                      View Documentation
                    </a>
                  </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="section pb-6 bg-gradient-info">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-favourite-28 text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Our Customers</h4>
                      <p className="text-white">
                         
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Awesome Support
                          </h5>
                          <p>
                            The Arctic Ocean freezes every winter and much of
                            the sea-ice then thaws every summer, and that
                            process will continue whatever.
                          </p>
                          <a
                            className="text-success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Modular Components
                          </h5>
                          <p>
                            The Arctic Ocean freezes every winter and much of
                            the sea-ice then thaws every summer, and that
                            process will continue whatever.
                          </p>
                          <a
                            className="text-warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-lg-1" lg="6">

                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/img-1-1200x1000.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        
                      </h4>
                      <p className="lead text-italic text-white">
                          
                      </p>
                    </blockquote>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  style={{fill: '#f4f5f7'}}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section> */}
        {/* <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">The amazing Team</h2>
                  <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Ryan Tompson</span>
                        <small className="h6 text-muted">Web Developer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-2-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Romina Hadid</span>
                        <small className="h6 text-muted">
                          Marketing Strategist
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-3-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Alexander Smith</span>
                        <small className="h6 text-muted">UI/UX Designer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">John Doe</span>
                        <small className="h6 text-muted">Founder and CEO</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}
        <section className="section section-lg bg-gradient-default">
          <a id="pricing" name="pricing"></a>
          <Container className="pt-sm pb-30">
            <Row className="text-center justify-content-center">
              <Col lg="10">
                <h2 className="display-3 text-white">Pricing</h2>
                <p className="lead text-white">
                  A plan for any size organization
                </p>
              </Col>
            </Row>
            <Pricing />
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg pt-30">
          <Container>
            <DeveloperDocsCallout />
          </Container>
        </section>
        {/* <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Have a Question?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            onFocus={(e) =>
                              this.setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ nameFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={(e) =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section> */}
      </main>
      <CardsFooter />
    </>
  );
}

export default Landing;
