import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col,
    Button
} from "reactstrap";
import ReactGA from 'react-ga4';

const getUrlForPlan = (planName) => {
    let baseUri = `https://app.convertlogistics.com/register`;
    if(process.env.NODE_ENV === 'development'){
        baseUri = `http://localhost/dashboard/register`;
    }
    return baseUri + `?plan=${planName.toLowerCase()}`;
}

const PLANS = [
    {
        name: 'Basic',
        ctaText: 'Start Plan',
        callout: 'For just starting out',
        url: getUrlForPlan('Basic'),
        price: {
            monthly: 14.99,
            annual: 14.99*12
        },
        features: [
            { title: '20,000 Requests / mo', included: true },
            { title: '1 Warehouse', included: true },
            { title: '50 Products', included: true },
            // { title: '1 Domain', included: true },
            { title: 'Support', included: true },
            { title: 'Estimated Delivery', included: true },
            { title: 'City, State, Zip Code', included: true },
            { title: 'GIS Coordinates', included: false },
            { title: 'Estimated Tax Rates*', included: false },
        ]
    },
    {
        name: 'Standard',
        ctaText: 'Start Plan',
        callout: 'For small stores',
        url: getUrlForPlan('Standard'),
        price: {
            monthly: 59.99,
            annual: 59.99*12
        },
        features: [
            { title: '500k Requests / mo', included: true },
            { title: '2 Warehouses', included: true },
            { title: '500 Products', included: true },
            // { title: '3 Domains', included: true },
            { title: 'Support', included: true },
            { title: 'Estimated Delivery', included: true },
            { title: 'City, State, Zip Code', included: true },
            { title: 'GIS Coordinates', included: true },
            { title: 'Estimated Tax Rates*', included: true },
        ]
    },
    {
        name: 'Premium',
        ctaText: 'Start Plan',
        callout: 'For medium sized stores',
        url: getUrlForPlan('Premium'),
        price: {
            monthly: 499.99,
            annual: 499.99*12
        },
        features: [
            { title: '5M Requests / mo', included: true },
            { title: '5 Warehouses', included: true },
            { title: '5k Products', included: true },
            // { title: '10 Domains', included: true },
            { title: 'Support', included: true },
            { title: 'Estimated Delivery', included: true },
            { title: 'City, State, Zip Code', included: true },
            { title: 'GIS Coordinates', included: true },
            { title: 'Estimated Tax Rates*', included: true },
        ]
    },
    {
        name: 'Enterprise',
        ctaText: 'Contact Us',
        callout: 'For large online stores',
        url: '/contact-us?plan=Enterprise',//'getUrlForPlan('Enterprise')',
        price: {
            monthly: 'Contact Us', //2499.99,
            annual: 'Contact Us', //2499.99*12
        },
        features: [
            { title: 'Unlimited Requests / mo', included: true },
            { title: 'Unlimited Warehouses', included: true },
            { title: 'Unlimited Products', included: true },
            // { title: '50+ Domains', included: true },
            { title: 'Support', included: true },
            { title: 'Estimated Delivery', included: true },
            { title: 'City, State, Zip Code', included: true },
            { title: 'GIS Coordinates', included: true },
            { title: 'Estimated Tax Rates*', included: true },
        ]
    }
]


const PriceCard = ({ idx, type }) => {
    const plan = PLANS[idx];
    let price = plan.price.monthly;
    if (type == "annual") {
        price = plan.price.annual;
    }
    return (
        <Col lg={12 / PLANS.length} className="p-lg-1">
            <Card className="bg-gradient-secondary shadow">
                <CardBody className="p-lg-4">
                    {typeof price === 'string' ? (
                        <h4 className="mb-1">{price}{" "}</h4>
                    ): (
                        <h4 className="mb-1">${price}{" "}<small style={{fontSize: '12px'}}>{type === "annual" ? '/ year' : "/ month"}</small></h4>
                    )}
                    <p className="m-0">{plan.name}</p>
                    <p className="small p-0">{plan.callout}</p>
                    <hr />
                    <div style={{ lineHeight: '2rem' }}>
                        {plan.features.map((feat, i) => {
                            return <div key={i}>
                                <i className={"pr-lg-2 lh-180 align-middle ni ni-" + (feat.included ? 'check-bold text-success' : 'fat-remove text-danger')} />
                                {feat.title}
                            </div>
                        })}
                    </div>
                    <a href={plan.url} target="_blank">
                        <Button color="default" type="button" className="mt-lg-4" block>{plan.ctaText}</Button>
                    </a>
                </CardBody>
            </Card>
        </Col>
    )
}


class Pricing extends React.Component {
    state = {
        tabs: 1
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    render() {
        return (
            <>
                {/* <div className="nav-wrapper">
                    <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                        style={{ width: '50%', margin: '0 auto' }}
                    >
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 1}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 1
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 1)}
                                href="#pablo"
                                role="tab"
                            >
                                Monthly
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 2}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 2
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 2)}
                                href="#pablo"
                                role="tab"
                            >
                                Annual
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div> */}
                <TabContent activeTab={"tabs" + this.state.tabs}>
                    <TabPane tabId="tabs1">
                        <Row className="row-grid mt-5">
                            {[...Array(PLANS.length)].map((v, i)=>{
                                return <PriceCard key={i} idx={i} type="monthly" />
                            })}
                        </Row>
                    </TabPane>
                    <TabPane tabId="tabs2">
                        <Row className="row-grid mt-5">
                            {[...Array(PLANS.length)].map((v, i)=>{
                                return <PriceCard key={i} idx={i} type="annual" />
                            })}
                        </Row>
                    </TabPane>
                </TabContent>
            </>
        );
    }
}

export default Pricing;