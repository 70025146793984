
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, Col, Container, Input, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { UpdateZipCodeDisplay } from 'UpdateZipCodeDisplay';
import { ZipCodeLine } from 'ZipCodeLine';
import { CovonyxContext, CovonyxProvider } from 'CovonyxContext';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { getDemoApiKey } from 'CovonyxContext';

export const sampleSkus = [
  { sku: '2932069', zipFrom: 90210, byline: 'Arrives by', displayType: 'default', render: 'city, state', name: 'Headphones (Rose)', price: '$279.99', image: '/img/headphones.webp' },
  { sku: '0279322', zipFrom: 10001, byline: 'Pickup on', displayType: 'default', render: 'city zip', name: 'Camera', price: '$479.99', image: '/img/camera.webp' },
  { sku: '9677746', zipFrom: 60611, byline: '<span class="small material-symbols-outlined">location_on</span>', displayType: 'default', render: 'zip', name: 'Woman\'s Shoes', price: '$49.99', image: '/shoe.webp' },
  { sku: '6293996', zipFrom: 77002, byline: 'Estimated Delivery', displayType: 'range', render: 'zip', name: 'Men\'s Shoes', price: '$24.99', image: '/img/shoes.webp' },
  { sku: '1651816', zipFrom: 33109, byline: 'Delivery by', displayType: 'range', render: 'zip', name: 'Instant Camera', price: '$36.99', image: '/img/camera2.webp' },
  { sku: '5044169', zipFrom: 20001, byline: 'Get it by', displayType: 'range', render: 'zip', name: 'Headphones', price: '$19.99', image: '/img/headphones2.webp' },]


export const modifySkus = [
  { sku: '2932069-1', name: 'Running Shoes', price: '$79.99', image: '/img/shoe2.webp' },]


export const EstimatedDeliveryDate = ({
  sku,
  imageUrl = null,
  zipCodeFrom = null,
  zipCodeTo = null,
  cacheResponse = true,
  byline = null,
  template = null,
  displayType = "default",
  dateFormat = null,
  themePrimary = null,
  themeSecondary = null,
  render = 'zip'
}) => {
  return <span
    data-zt-type="estimated-delivery"
    data-zt-sku={sku}
    data-zt-image-url={imageUrl}
    data-zt-cache={cacheResponse}
    data-zt-show-countdown="true"
    data-zt-origin-zip={zipCodeFrom}
    data-zt-destination-zip={zipCodeTo}
    data-zt-byline={byline}
    data-zt-template={template}
    data-zt-display-type={displayType}
    data-zt-date-format={dateFormat}
    data-zt-theme-primary={themePrimary}
    data-zt-theme-secondary={themeSecondary}
    data-zt-render={render}
  ></span>
}

export const EstimatedDeliveryDateProductCard = (props) => {
  const {
    zipCodeTo = null,
    zipCodeFrom = null,
    sku,
    price,
    name,
    cacheResponse = true,
    byline = null,
    template = null,
    displayType = "default",
    imageUrl,
    dateFormat = "D, M jS",
    themePrimary = null,
    themeSecondary = null,
    render = null
  } = props;
  const [showAddToBagAlert, setShowAddToBagAlert] = useState(false);
  const [showMoreAboutThisDemo, setShowMoreAboutThisDemo] = useState(false);

  return (<Card className="zip-guess" style={{ width: '100%' }}>
    <CardBody>
      <Link to={`/demo/delivery?sku=${sku}`}>
        <img src={imageUrl} alt="" height={250} style={{ width: '100%', maxHeight: 250 }} />
        <div className="display-4">{name}</div>
      </Link>
      <strong className="bold">{price}</strong>
      <br />
      <br />
      <EstimatedDeliveryDate
        sku={sku}
        imageUrl={imageUrl}
        name={name}
        price={price}
        zipCodeFrom={zipCodeFrom}
        zipCodeTo={zipCodeTo}
        cacheResponse={cacheResponse}
        byline={byline}
        template={template}
        displayType={displayType}
        dateFormat={dateFormat}
        themePrimary={themePrimary}
        themeSecondary={themeSecondary}
        render={render}
      />
      <Button className="btn btn-danger btn-block mt-3" onClick={() => { setShowAddToBagAlert(!showAddToBagAlert) }}>Add to Bag</Button>
      {showAddToBagAlert && (
        <Alert color="warning" className="mt-sm-2">This is just a demo. This button doesn't do anything 🙈. The real product is the delivery messaging above 👆.</Alert>
      )}
      {zipCodeFrom !== null && byline !== null && (<>
        <a href="#" className="small" role="button" onClick={(e) => {
          e.preventDefault();
          setShowMoreAboutThisDemo(!showMoreAboutThisDemo)
        }}>Learn more about this estimate</a>
        {showMoreAboutThisDemo && (
          <>
            <div><small>* Warehouse located in: {zipCodeFrom}</small></div>
            <div><small>* Uses the '{displayType}' display type</small></div>
            <div><small>* Uses the configured byline '{byline}'</small></div>
            <div><small>* Uses the render mode '{render}'</small></div>
          </>
        )}
      </>)}
    </CardBody>
  </Card>);
}
const DemoEstimatedDeliveryDatesRender = (props) => {
  return (<div className="zip-grid">
    <>
      {[...Array(sampleSkus.length)].map((v, i) => {
        return <EstimatedDeliveryDateProductCard
          sku={sampleSkus[i].sku}
          zipCodeFrom={sampleSkus[i].zipFrom}
          imageUrl={sampleSkus[i].image}
          name={sampleSkus[i].name}
          price={sampleSkus[i].price}
          byline={sampleSkus[i].byline}
          displayType={sampleSkus[i].displayType}
          render={sampleSkus[i].render}
          key={i} />
      })}
    </>
  </div>)
}

const EstimatedDeliveryDateDemoPDP = (props) => {
  const { sku } = props;
  return (sku && sku.image ? (<Container>
    <Row>
      <Col xs="12" sm="8">
        <Card style={{ padding: '1rem' }}>
          <img src={sku.image} alt="" style={{ width: '100%' }} />
        </Card>
      </Col>
      <Col xs="12" sm="4">
        <Card style={{ padding: '1rem' }}>
          <div className="display-4">{sku.name}</div>
          <strong className="bold">{sku.price}</strong>
          <br />
          <EstimatedDeliveryDate sku={sku.sku} imageUrl={sku.image} zipCodeFrom={sku.zipFrom} displayType={sku.displayType} render={sku.render} />
          <br />
          <button className="btn btn-danger btn-block mt-3">Add to Bag</button>
        </Card>
      </Col>
    </Row>
  </Container>) : null
  )
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EstimatedDeliveryDatesDisplay() {
  const query = useQuery();
  const location = useLocation();
  const sku = query.get('sku');

  React.useEffect(() => {
    if (window._zt) {
      window._zt.load()
    }
  }, [location]);

  const isPDP = () => {
    return sku !== null && sku !== undefined;
  }

  return (
    <CovonyxProvider apiKey={getDemoApiKey()}>
      <div className="nav-wrapper">
        <Nav
          className="nav-fill flex-xs-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <Link
              aria-selected={!isPDP()}
              className={'mr-md-3 nav-link' + (!isPDP() ? ' active' : '')}
              to="/demo/delivery"
              role="tab"
            >
              Product Listing Page Demo
            </Link>
          </NavItem>
          <NavItem>
            <Link
              aria-selected={isPDP()}
              className={'nav-link' + (isPDP() ? ' active' : '')}
              to={"/demo/delivery?sku=" + sampleSkus[0].sku}
              role="tab"

            >
              Product Details Page Demo
            </Link>
          </NavItem>
        </Nav>
      </div>
      {!isPDP() ? (<>
        <p className="container bg-white p-3">
          All estimates in this demo vary the origin zip code location (the warehouse) to simulate multiple shipping warehouse locations. You can configure multiple warehouse locations and the delivery estimates would be based on the nearest one to the end user.
          The destination zip code is what is displayed below and is determined by your location. You can update the zip code below and all others will update as well.
          This demo shows how the SDK will batch process multiple lookups at once (even if shipping from different warehouses) so your website remains performant.</p>
        <DemoEstimatedDeliveryDatesRender />
      </>
      ) : (<>
        <p className="container bg-white p-3">
          All estimates in this demo are based on an origin zip code location (the warehouse) of <code>90210</code> - Beverly Hills, CA. This demo is designed to showcase how Estimated Delivery Dates could be displayed on your product page.
        </p>
        <EstimatedDeliveryDateDemoPDP sku={sampleSkus.filter((s, i) => s.sku === sku)[0]} />
      </>
      )}
    </CovonyxProvider>
  )
}

export function EstimatedDeliveryDateProductCardDisplay({ skuId = 0 }) {
  const sku = sampleSkus[skuId]

  React.useEffect(() => {
    if (window._zt) {
      window._zt.load()
    }
  }, []);

  return (
    <CovonyxProvider apiKey={getDemoApiKey()}>
      <EstimatedDeliveryDateProductCard sku={sku.sku}
        imageUrl={sku.image}
        name={sku.name}
        price={sku.price}
        displayType={sku.displayType}
        // themePrimary="#2a5a82"
        themePrimary="#3a75a6"
      />
    </CovonyxProvider>
  )
}