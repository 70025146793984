import React from 'react';
import ReactDOM from "react-dom/client";


class COVONYX_STUB {
  getZipCode = () => { }
  getCity = () => { }
  getState = () => { }
  getStateCode = () => { }
  get = () => { }
  getLocationData = () => { }
  getMapUrl = () => { }
  setZipCode = (z) => { return null; }
  getEstimatedTaxAmount = (z) => { return z; }
  getEstimatedTotalAmount = (z) => { return z; }
  getLearnMore = () => { return <></>; }
  store = (z) => { return null; }
  retrive = () => { }
}

export const CovonyxContext = React.createContext();

export const getDomain = () => {
  if(window.location.hostname.split('.').length - 1 === 2){
    return window.location.hostname.substring(window.location.hostname.indexOf('.')+1)
  }
  return window.location.hostname;
}

export const getDemoApiKey = () => {
  if(window.location.hostname.indexOf('covonyx') > -1){ 
    return 'kxI4Jv9VQtaXdaTNYV4Y3EotC';
  }else{
    return 'QBjznN0k0zYNBAXOea9baNQp9';
  }
}


export const CovonyxProvider = ({ apiKey, environment = null, theme = {primary: null, secondary: null}, children }) => {
  const [zipCode, setZCode] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [stateCode, setStateCode] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [mapUrl, setMapUrl] = React.useState(null);
  const [estimatedTax, setEstimatedTax] = React.useState(0.00);
  const [estimatedTaxPercent, setEstimatedTaxPercent] = React.useState(0.00);
  const [estimatedTaxRate, setEstimatedTaxRate] = React.useState(0.00);
  const [additionalTaxLines, setAdditionalTaxLines] = React.useState([]);

  const [zApi, setZApi] = React.useState(new COVONYX_STUB());
  const [mounted, setMouted] = React.useState(false);

  environment = environment === null ? (process.env.NODE_ENV === 'production' ? 'production' : 'sandbox') : environment;

  const setData = (d) => {
    if (d.zip) setZCode(d.zip);
    if (d.city) setCity(d.city);
    if (d.state) setState(d.state);
    if (d.state_code) setStateCode(d.state_code);
    if (d.map_url) setMapUrl(d.map_url)
    if (d.tax && d.tax.estimated_combined_rate) setEstimatedTax(d.tax.estimated_combined_rate)
    if (d.tax && d.tax.estimated_combined_rate) setEstimatedTaxRate((d.tax.estimated_combined_rate * 1).toFixed(4))
    if (d.tax && d.tax.estimated_combined_rate) setEstimatedTaxPercent(((d.tax.estimated_combined_rate) * 100).toFixed(3));
    if (d.tax && d.tax.additional_tax_lines) { setAdditionalTaxLines(d.tax.additional_tax_lines) } else { setAdditionalTaxLines([]) }
  }

  React.useEffect(() => {
    if (!mounted) {

      setLoading(true);
      if(!document.getElementById('zt-script')){
        const script = document.createElement('script');
        script.setAttribute('data-zt-loaded', true);
        script.innerHTML = `
            window.zConfig = {
                    apiKey: "${apiKey}",
                    environment: "${environment}",
                    theme: {
                      primary: ${theme.primary},
                      secondary: ${theme.secondary}
                    }
                };
                if(!document.getElementById("zt-script")){let t=document.createElement("script");t.id="zt-script",t.async=!0,t.src="${process.env.REACT_APP_ENDPOINT_SDK}/z.js",document.body.appendChild(t)}`;
        document.body.appendChild(script);
      }
      let count = 0;
      const intervalID = setInterval(( ) => {
        if(count > 10){
          clearInterval(intervalID);
        }
        if(document.querySelector('[data-zt-loaded') && window._zt ){
          clearInterval(intervalID)
          window._zt.retrive().then(d => {
            setLoading(false);
            setData(d)
            setTimeout(()=>{
              window._zt.load()
            }, 1)
          });
        }
        count++;
      }, 350)

      setMouted(true)
    }
  }, [mounted])
  return (
    <CovonyxContext.Provider value={{
      zipCode: zipCode,
      city: city,
      state: state,
      stateCode: stateCode,
      mapUrl: mapUrl,
      estimatedTax: estimatedTax,
      estimatedTaxPercent: estimatedTaxPercent,
      estimatedTaxRate: estimatedTaxRate,
      additionalTaxLines: additionalTaxLines,
      apiKey: apiKey,
      loading: loading,
      environment: environment,
      getLocationData: () => {
        return zApi.get();
      },
      setZipCode: (zCode) => {
        if (zCode.length > 2) {
          setLoading(true);
          zApi.setZipCode(zCode).then((d) => {
            setData(d)
            setLoading(false);
          })
        }
      },
      setData: (data) => {
        setData(data);
      },
      getEstimatedTaxAmount: (amount) => {
        return amount * estimatedTaxRate;
      },
      getEstimatedTotalAmount: (amount) => {
        let baseAmount = amount * estimatedTaxRate;
        if (additionalTaxLines.length > 0) {
          additionalTaxLines.forEach((line, idx) => {
            baseAmount += line.amount;
          })
        }
        return baseAmount + amount;
      },
      getEstimatedDeliveryDate: (zCodeFrom, zCodeTo = null, useCache = true) => {
        return window._zt.getEstimatedDeliveryDate(zCodeFrom, useCache, zCodeTo === null ? zipCode : zCodeTo);
      },
      getLearnMore: () => {
        const learnMore = window._zt.getLearnMore();
        return <i dangerouslySetInnerHTML={{ __html: learnMore.innerHTML }} onClick={(e) => learnMore.onclick(e)} />;
      }
    }}>
      {children}
    </CovonyxContext.Provider>
  )
}
