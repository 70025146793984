import { BackgroundFloatingBoxes } from "components/BackgroundFloatingBoxes"
import DemoNavbar from "components/Navbars/DemoNavbar"
import EstimatedDeliveryDatesDisplay from "./EstimatedDeliveryDatesDisplay"
// reactstrap components
import {
    Container,
    Row,
    Col,
    CardImg
} from "reactstrap";
import React, { useEffect } from "react";
import CardsFooter from "components/Footers/CardsFooter";
import { EstimatedDeliveryDateProductCardDemo } from "./EstimatedDeliveryDateProductCardDemo";
import DeveloperDocsCallout from "components/DeveloperDocsCallout";
import { DesktopAndTabletDevice, MobileDevice } from "react-detect";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";


const sampleJSInstall = `
  \`\`\`html
    
  <script>
  window.zConfig = { apiKey: "<YOUR API KEY>" };
  if(!document.getElementById("zt-script")){let t=document.createElement("script");t.id="zt-script",t.async=!0,t.src="https://sdk.convertlogistics.com/z.js",document.body.appendChild(t)}
  </script>

  <div data-zt-type="estimated-delivery" 
       data-zt-show-countdown="true"
       data-zt-display-type="range"
       data-zt-render="city, state zip"
       data-zt-name="<PRODUCT_NAME>"
       data-zt-sku="<PRODUCT_SKU>"
       data-zt-image-url="<PRODUCT_IMAGE_URL>"
       data-zt-url="<PRODUCT_URL>"
       data-zt-in-stock="<IN_STOCK>"
       data-zt-price="<PRODUCT_PRICE_IN_CENTS>"></div>
  \`\`\`
`;




export const EstimatedDeliveryDateDemo = () => {

  useEffect(() => {
    hljs.highlightAll();
  }, []);
    return (<>
        <DemoNavbar />
        <main>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <BackgroundFloatingBoxes />
                    <Container className="d-flex mt-5">
                        <div className="col">
                            <h1 className="display-3 text-white">
                                Estimated Shipping & Delivery Dates
                            </h1>
                            <Row className="row-grid bg-white p-md-5">
                                <Col className="order-md-1" md="4">
                                    <CardImg
                                        alt="..."
                                        src={'/img/drone-delivery-animate.svg'}
                                        top
                                    />
                                </Col>
                                <Col className="order-md-2" md="8">
                                    <div className="p-3 pl-md-5">
                                        <p>
                                            With the power of our location detection, we can predict how long it will take to deliver an item to a customer <i>before</i> they get to checkout and purchase the item.
                                        </p>
                                        <p>
                                            Our system will take into account your company business days, holidays, warehouse locations, product inventory, and fulfillment lead times along with any upcoming carrier holidays, delays, routing, and any major weather events. Combined with an accurate prediction of the customers location, our system will return an estimated delivery date unique to the item and customer.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <br />
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>

                <DesktopAndTabletDevice>
                    <Container style={{ marginTop: -200 }}>
                        <DeveloperDocsCallout />
                    </Container>
                </DesktopAndTabletDevice>
                <MobileDevice>
                    <Container style={{ marginTop: -100 }}>
                        <DeveloperDocsCallout />
                    </Container>
                </MobileDevice>
                {/* 1st Hero Variation */}
            </div>
            <section className="section bg-secondary">
                <Container>
                    <h3 className="pl-md-3">Example Demo</h3>
                    <EstimatedDeliveryDatesDisplay />
                </Container>
            </section>
            <section className="section">
                <Container>
                    <h3 className="pl-md-3">Get up and running today!</h3>
                    
                    <div dangerouslySetInnerHTML={{ __html: marked(sampleJSInstall) }}></div>
                    <p>Thats it! It's that simple.</p>
                    <p>To learn more about the options shown above and others that are available, see our <a target="_blank" href={process.env.REACT_APP_ENDPOINT_DOCS + '/docs/category/estimated-delivery-dates'}>developer documentation</a></p>

                </Container>
            </section>
            <section className="section bg-secondary">
                <EstimatedDeliveryDateProductCardDemo />
            </section>

        </main>
        <br />
        <br />
        <br />
        <br />
        <CardsFooter />
    </>
    )
}