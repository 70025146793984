import { Button, Card, Col, Row } from "reactstrap";

export default function DeveloperDocsCallout() {
    return (
        <Card className="bg-gradient-warning shadow-lg border-0">
          <div className="p-5">
            <Row className="align-items-center">
              <Col lg="8">
                <h3 className="text-white">
                  Built with developers in mind
                </h3>
                <p className="lead text-white mt-3">
                  With a Javascript SDK and a powerful REST API, you can get up and running in less than 10 minutes!
                </p>
              </Col>
              <Col className="ml-lg-auto" lg="3">
                <Button
                  block
                  className="btn-white"
                  color="default"
                  href={process.env.REACT_APP_ENDPOINT_DOCS}
                  size="lg"
                >
                  <i className="ni ni-books align-middle mr-2" />
                  View Docs
                </Button>
              </Col>
            </Row>
          </div>
        </Card>)
}